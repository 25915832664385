import * as React from 'react';
import Chip from '@mui/material/Chip';


function ChipSatge(props) {

  const chipColor = () => {
    switch (props.value) {
      case 'OC en proceso':
        return { color: '#175CD3', background: '#EFF8FF' };
      case 'Comprado y en alquiler':
        return { color: '#067647', background: '#ECFDF3' };
      case 'Comprado y en venta':
        return { color: '#b512cd', background: '#fceefe' };
      default:
        return { color: '#717171', background: '#F5F5F5' };
    }
  }

  return (

    <Chip label={"• "+props.value} style={chipColor()} />

  );
};

export default ChipSatge;