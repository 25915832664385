import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fontsource/roboto/400.css';
import './App.css';
import Header from './components/Elements/Header';
import Footer from './components/Elements/Footer';
import PasswordRecover from './components/PasswordRecover';
import PasswordReset from './components/PasswordReset';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';

import { ThemeProvider } from '@mui/material';
import Theme_lp from './components/Theme/Theme_lp';

function App() {

  const config = {
    backend_api: process.env.REACT_APP_API_URL,
  };

  return (
    <div>
      <ThemeProvider theme={Theme_lp}>
        {sessionStorage.getItem("loggedIn") ? <Header /> : null}
        <BrowserRouter>
          <Routes>
            <Route index element={<Login config={config} />} />
            <Route path="/login" element={<Login config={config} />} />
            <Route path="/recover" element={<PasswordRecover config={config} />} />
            <Route path="/reset" element={<PasswordReset config={config} />} />
            <Route path="/logout" element={<Logout config={config} />} />
            <Route path="/dashboard" element={<Dashboard config={config} />} />
          </Routes>
        </BrowserRouter>
        {sessionStorage.getItem("loggedIn") ? <Footer /> : null}
      </ThemeProvider>
    </div>
  );
}

export default App;