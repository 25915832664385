import { createTheme } from '@mui/material';




const pcform23Theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#30f5d2',
      contrastText: '#0a1a80',
    }
  },
  typography: {
    fontFamily: 'Inter',
    button: {
        fontWeight: 600,
        textTransform: 'none',
        borderColor: '#B9B9B9',
    },
    caption: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
    },
    h1: {
      fontFamily: 'Titillium Web',
      fontSize: 40,
      fontweight: 400,
    },
    h2: {
      fontFamily: 'Titillium Web',
      fontSize: 30,
      fontweight: 400,
    },
    h3: {
      fontFamily: 'Titillium Web',
      fontSize: 24,
      fontweight: 400,
    },
    body1: {
      fontSize: 14,
      fontweight: 300,
      color: '#667085',
    },
  },
  shape: {
    borderRadius: 5,
  },
  spacing: 8,

  components: {

    MuiButton: {

      styleOverrides: {
          outlined: {
              borderColor: '#B9B9B9',
              /*borderTopWidth: '10px',*/
          },
          sizeSmall: {
            fontSize: '11px',
            padding: '6px 12px',
          },
          sizeMedium: {
            fontSize: '13px',
            padding: '10px 18px',
          },
          sizeLarge: {
            fontSize: '20px',
            padding: '4px 0',
          },
      },

      /*
      // Para crear estilos de botón personalizado
      variants: [
        {
          props: { variant: "pcf23BotVerde" }, // Para asignar este estilo añadir variant="pcf23BotVerde" al boton
          style: {
            fontWeight: "bold",
            backgroundColor: '#30f5d2',
            contrastText: '#0a1a80',
          }
        }
      ] 
      /**/ 

    },



    MuiFormLabel: {

      styleOverrides: {
        
        root: {
          //-> Solo funciona si se eliminan las propiedades CSS previamente asignadas por CSS normales. Ejemplo
          // .pcf23-form input {
          //    border: none;
          //    height: 44px;
          //    font-size: inherit;
          //  } 
          // fontWeight: 700,
          fontSize: '13px',
          lineHeight: '1em'
          // paddingTop: 0,
          // paddingBottom: 0,
        },
        sizeSmall: {
          // fontSize: '60px', //-> NO FUNCIONA
          //padding: '60px 60px',
        },
        input: {
          //fontSize: '60px',
          //paddingTop: '10px',
          //paddingBottom: '10px',
        }
      },
    },

    MuiLinearProgress: {

      styleOverrides: {

        root: {
          //-> Solo funciona si se eliminan las propiedades CSS previamente asignadas por CSS normales. Ejemplo
          // .pcf23-form input {
          //    border: none;
          //    height: 44px;
          //    font-size: inherit;
          //  } 
          // fontWeight: 700,
          height: 9,
          backgroundColor: "#30f5d2",
          // paddingTop: 0,
          // paddingBottom: 0,
        },
        
        sizeSmall: {
          // fontSize: '60px', //-> NO FUNCIONA
          //padding: '60px 60px',
        },
        input: {
          //fontSize: '60px',
          //paddingTop: '10px',
          //paddingBottom: '10px',
        }
      },
    },

    MuiTooltip: {
        
        styleOverrides: {
          tooltip: {
            fontSize: "14px",
            fontWeight: 300,
            lineHeight: "20px",
            color: "#000000",
            backgroundColor: "#EAECF0",
            padding: "20px 30px",
          }
        },
      },



  }

    

});

export default pcform23Theme;