import axios from "axios";
import React, { useState } from "react";
import "@fontsource/roboto/400.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import Ondara from '../assets/ondara.png';
import Stack from '@mui/material/Stack';

import LoginIlustration from './Elements/LoginIlustration';

function PasswordRecover(props) {

  const [email, setEmail] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [showForm, setShowForm] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(props.config.backend_api + "recover.php", {
        email: email,
      })
      .then((response) => {
        if (response.data.status === "success") {
          setShowForm(false);
        }
        setApiResponse(response.data.message);
      })
      .catch((error) => {
        //console.error(error);
        setApiResponse(error.response.data.message);
      });
  };

  return (
    <Container disableGutters maxWidth={false}>


      <Grid container spacing={0}>
        <Grid xs={12} lg={6} >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handleSubmit}>
        <Stack sx={{ mt: 2, mb: 2 }} spacing={2} alignItems="center" width={320}>

        <img src={Ondara} alt="Ondara Capital" width="180" />
          <Typography variant="h2">
          Recuperar contraseña
          </Typography>
          <Typography variant="body1" align="center">
          Indica tu dirección de correo electrónico y recibirás un enlace para recuperar la contraseña.
          </Typography>


          {apiResponse && (
            <Alert
              style={{ marginBottom: "20px" }}
              severity={showForm ? "error" : "success"}
            >
              {apiResponse}
            </Alert>
          )}


          {showForm ?

            <><TextField
              type="email"
              value={email}
              onChange={handleEmailChange}
              variant="filled"
              id="filled-basic"
              label="Correo electrónico"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

              <Button variant="contained" type="submit" style={{ alignSelf: 'stretch' }}>
                Enviar
              </Button>
            </> : null
          }
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: "20px" }}
          >

          </Typography>
        </Stack>
        <div style={{ width: '100', textAlign: 'right', fontWeight: '600', fontSize: '14' }}>
          <Link underline="none" href="/login">Ir a la pantalla de inicio</Link>
        </div>
      </form>
      </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <LoginIlustration></LoginIlustration>
        </Grid>
      </Grid>





    </Container>
  );
}

export default PasswordRecover;
