import * as React from 'react';
import axios from "axios";
import { useState } from "react";
import Popover from '@mui/material/Popover';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Alert } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


function Documentos(props) {

  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [documentosLoaded, setDocumentosLoaded] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (documentos.length === 0) {
      // Cargar documentos de la oferta
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      //console.log(userData.userId);
      //console.log(props.id);
      axios
        .post(props.config.backend_api + "getdocuments.php", {
          userId: userData.userId,
          id_oferta: props.id_oferta,
          id_oc: props.id_oc,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status === "success") {
            //console.log(response.data.data.docs);
            setDocumentos(response.data.data.docs);
          }
          if (response.data.status === "error") {
            //window.location.href = "/login";
            //setApiResponse(response.data.message);
            setError(response.data.message);
          }
          setDocumentosLoaded(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  return (


    <div>
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        <PictureAsPdfIcon style={{ color: 'red' }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {error && (
          <Alert style={{ marginBottom: "0" }} severity="error">
            {error}
          </Alert>
        )}
        {documentos.length === 0 && !documentosLoaded ? <Container sx={{ mt: 1, mb:1 }}><CircularProgress /></Container> :
          <Container sx={{ mt: 1, mb:1 }}>
            {documentos.length ? <Typography >Documentación disponible:</Typography> : null}
            {documentos.map((documento) => (
              <div key={documento.id}>
                <Button sx={{ my: 1 }} href={props.config.backend_api + 'getfile.php?s='+documento.source+'&id=' + documento.file_id} target="_blank">
                  <PictureAsPdfIcon style={{ color: 'red' }} fontSize="small" sx={{ mr:1 }}/> {documento.File_Name}
                </Button>
              </div>
            ))}
          </Container>
        }
      </Popover>
    </div>

  );
};

export default Documentos;