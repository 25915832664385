import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

const PclpPaper = styled(Paper)(({ theme }) => ({
  width: 240,
  height: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
}));

const tooltipTasaretorno = "La rentabilidad anualizada neta (NAR) es un indicador anualizado de la rentabilidad de todas las inversiones cerradas que has realizado desde que empezaste a operar en Ondara. Incluye todos los elementos que puedan afectar a tu rentabilidad, tales como retrasos e impagos.";

export default function Resumen(data) {


  return (
    <>
      <div className="pclp-resume-section pclp-resume-cerradas">
        <div className="pclp-section-title">Inversiones cerradas</div>
        <Stack direction="row" spacing={2} justifyContent="center" useFlexGap flexWrap="wrap">

          <PclpPaper square={false} elevation={6} >
            <div className="pclp-paper-title">Importe invertido</div>
            <div className="pclp-paper-value">{data.data.totalInvertidoCerradas ? data.data.totalInvertidoCerradas.toLocaleString(navigator.language, { maximumFractionDigits: 0 }) + '€' : null}</div>
          </PclpPaper>

          <PclpPaper square={false} elevation={6}>
            <div className="pclp-paper-title">Tasa de retorno anual
              <Tooltip placement="right-start" TransitionComponent={Fade} TransitionProps={{ timeout: 400 }} title={tooltipTasaretorno}><InfoOutlined fontSize="small" className="pclp-paper-tooltip-open"/>
              </Tooltip>
            </div>
            <div className="pclp-paper-value">{data.data.tasaRetornoAnualCerradas ? data.data.tasaRetornoAnualCerradas.toLocaleString(navigator.language, { maximumFractionDigits: 2 }) : null}% </div>
            <div className="pclp-paper-foot">Beneficio: <span className="pclp-paper-foot-value">{data.data.totalBeneficioCerradas ? data.data.totalBeneficioCerradas.toLocaleString(navigator.language, { maximumFractionDigits: 0 }) + '€' : null}</span></div>
          </PclpPaper>

          <PclpPaper square={false} elevation={6}>
            <div className="pclp-paper-title">Operaciones</div>
            <div className="pclp-paper-value">{data.data.totalInversionesCerradas}</div>
          </PclpPaper>

        </Stack>
      </div>


      <div className="pclp-resume-section pclp-resume-curso">
        <div className="pclp-section-title">Inversiones en curso</div>
        <Stack direction="row" spacing={2} justifyContent="center" useFlexGap flexWrap="wrap">

          <PclpPaper square={false} elevation={6}>
            <div className="pclp-paper-title">Importe invertido</div>
            <div className="pclp-paper-value">{data.data.totalInvertidoEnCurso ? data.data.totalInvertidoEnCurso.toLocaleString(navigator.language, { maximumFractionDigits: 0 }) + '€' : null}</div>
          </PclpPaper>

          <PclpPaper square={false} elevation={6}>
            <div className="pclp-paper-title">Tipo interés objetivo</div>
            <div className="pclp-paper-value">{data.data.tirEnCurso ? data.data.tirEnCurso.toLocaleString(navigator.language, { maximumFractionDigits: 2 }) : null}% </div>
            <div className="pclp-paper-foot">Beneficio estimado: <span className="pclp-paper-foot-value">{data.data.totalBeneficioEnCurso ? data.data.totalBeneficioEnCurso.toLocaleString(navigator.language, { maximumFractionDigits: 0 }) + '€' : null}</span></div>
          </PclpPaper>

          <PclpPaper square={false} elevation={6}>
            <div className="pclp-paper-title">Operaciones</div>
            <div className="pclp-paper-value">{data.data.totalInversionesEnCurso}</div>
          </PclpPaper>

        </Stack>
      </div>
      </>
  );
}
