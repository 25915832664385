import Typography from "@mui/material/Typography";
import login_bg from '../../assets/login_bg.jpg';



const styles = {
  loginIllustration: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${login_bg})`,
    backgroundSize: 'contain',
  }
};


function LoginIlustration() {
  return (
    <div style={styles.loginIllustration}>
      <div style={{ position: 'absolute', bottom: '80px', left: '80px', color: '#fff' }}>
        <Typography variant="h1" component="h1">We Make Sure</Typography>
        <Typography sx={{ fontSize: 12, fontFamily: 'Titillium Web', color:'#ffffff'}} >
          © {new Date().getFullYear()} Ondara.
        </Typography>
      </div>
    </div>
  );
};

export default LoginIlustration;