import axios from "axios";
import React, { useState, useEffect } from "react";
import "@fontsource/roboto/400.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordReset(props) {

  const [password, setPassword] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  /*
  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  const handleGeneratePassword = () => {
    setPassword(generatePassword());
  };
  */

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  // Comprobar si el token de la URL es válido con la funcion checktoken de la api
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const id = urlParams.get("id");

    axios
      .post(props.config.backend_api + "checktoken.php", {
        token: token,
        id: id,
      })
      .then((response) => {
        //console.log(response);
        if (response.data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.data)
          );
        }
        if (response.data.status === "error") {
          //window.location.href = "/login";
          setApiResponse(response.data.message);
          setError(true);
        }    

      })
      .catch((error) => {
        console.error(error);
      });

  }, [props.config.backend_api]);






  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(props.config.backend_api + "reset.php", {
        password: password,
        userId:  userData?.userId,
      })
      .then((response) => {
        console.log(response);
        setApiResponse(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        setApiResponse(error.response.data.message);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5%",
      }}
    >
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Typography
            style={{ marginBottom: "20px" }}
            variant="h2"
            component="h2"
          >
            Cambiar contraseña
          </Typography>
          {apiResponse && (
            <Alert
              style={{ marginBottom: "20px" }}
              severity={apiResponse.includes("cambiado") ? "success" : "error"}
            >
              {apiResponse}
            </Alert>
          )}

          { error ? null :
            <div>
              <TextField
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                variant="filled"
                id="filled-basic"
                label="Contraseña"
                fullWidth
                style={{ marginBottom: "10px" }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
            </div>
          }

          {apiResponse.includes("cambiado") || error ? null : 
            <Button variant="contained" type="submit">
              Guardar la contraseña
            </Button>
          }
          
          <Typography
            variant="body1"
            gutterBottom
            style={{ marginTop: "20px" }}
          >
            <Link href="/login">Ir a la pantalla de inicio</Link>
          </Typography>
        </form>
      </Container>
    </Box>
  );
}

export default PasswordReset;
