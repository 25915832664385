import Box from '@mui/joy/Box';
import Stack from '@mui/material/Stack';


function Footer() {

  const userData = JSON.parse(sessionStorage.getItem("userData"));

  return (
    <>
      {!userData.userData ? null :
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <Stack sx={{ fontSize: 12, fontFamily: 'Titillium Web', mt: 2, mb: 2 }} spacing={2} direction="row" justifyContent="space-between">
            <div>Ondara Capital, S.L. (B67229757) es un sociedad supervisada y registrada en el Banco de España con el código de Entidad D552 - We Make Sure.</div>
            <div>© {new Date().getFullYear()} Ondara.</div>
          </Stack>
        </Box>

      }
    </>
  );
};

export default Footer;