import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Stack from '@mui/material/Stack';
import Ondara from '../assets/ondara.png';

import LoginIlustration from './Elements/LoginIlustration';

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(props.config.backend_api + "login.php", {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem(
            "userData",
            JSON.stringify(response.data.data)
          );

          window.location.href = "/dashboard";
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Container disableGutters maxWidth={false}>


      <Grid container spacing={0}>
        <Grid xs={12} lg={6} >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={handleSubmit}>
              <Stack sx={{ mt: 2, mb: 2 }} spacing={2} alignItems="center" width={320}>
                <img src={Ondara} alt="Ondara Capital" width="180" />
                <Typography variant="h2">
                  Bienvenido
                </Typography>
                <Typography variant="body1">
                  Introduce tus credenciales
                </Typography>
                {error && (
                  <Alert style={{ marginBottom: "20px" }} severity="error" >
                    {error}
                  </Alert>
                )}
                <TextField
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  variant="filled"
                  id="email"
                  label="Correo electrónico"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  variant="filled"
                  id="password"
                  label="Contraseña"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Button variant="contained" type="submit" style={{ alignSelf: 'stretch' }}>
                  Identificarse
                </Button>

              </Stack>

              <div style={{ width: '100', textAlign: 'right', fontWeight: '600', fontSize: '14' }}>
                <Link underline="none" href="/recover" >Recuperar contraseña</Link>
              </div>

            </form>
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <LoginIlustration></LoginIlustration>
        </Grid>
      </Grid>





    </Container>
  );
}

export default Login;


