import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";


import Container from "@mui/material/Container";
//import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from "@mui/material/Box";

import Resumen from './Resumen';
import TableEnCurso from './TableEnCurso';
import TableCerradas from './TableCerradas';


import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';



function Dashboard(props) {
  const navigate = useNavigate();
  //const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [error, setError] = useState("");

  const [resumen, setResumen] = useState([]);
  const [rowsPendientes, setRowsPendientes] = useState([]);
  const [rowsRealizadas, setRowsRealizadas] = useState([]);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }
  }, [navigate]);


  const [activeTab, setActiveTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };



  useEffect(() => {

    if (sessionStorage.getItem("loggedIn")) {
      //const userId = sessionStorage.getItem("userId");
      const userData = JSON.parse(sessionStorage.getItem("userData"));

      axios
        .post(props.config.backend_api + "getinvestordata.php", {
          userId: userData.userId,
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status === "success") {
            setRowsPendientes(response.data.data.pendientes);
            setRowsRealizadas(response.data.data.realizadas);
            setResumen(response.data.data.resumen);
          }
          if (response.data.status === "error") {
            //window.location.href = "/login";
            //setApiResponse(response.data.message);
            setError("Ocurrió un error al cargar los datos.");
          }

        })
        .catch((error) => {
          console.error(error);
        });
    }


  }, [props.config.backend_api]);


  return (
    <div>



      <Container disableGutters maxWidth={false} >



        {error && (
          <Alert style={{ marginBottom: "20px" }} severity="error">
            {error}
          </Alert>
        )}

        <Box sx={{ backgroundColor: '#000000', color: '#ffffff' }}>
          <Container disableGutters maxWidth='xl' >
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ backgroundColor: '#000000', color: '#ffffff' }}
                  textColor="inherit"
                  indicatorColor="#ffffff"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#ffffff',
                    }
                  }}
                >
                  <Tab label="Resumen" value="1" />
                  <Tab label="Inversiones en curso" value="2" />
                  <Tab label="Inversiones cerradas" value="3" />
                </TabList>
              </Box>

            </TabContext>
          </Container>

        </Box>


        <Container disableGutters maxWidth='xl' >
          <TabContext value={activeTab}>
            <TabPanel value="1" sx={{ p:2 }}>
              <div className="pcpl-tab-header">
                <h1>Resumen</h1> 
              </div>
              <Resumen data={resumen} />
            </TabPanel>
            <TabPanel value="2">
              <div className="pcpl-tab-header">
                <h1>Inversiones en curso</h1>
                <p>Los cálculos de beneficios, rentabilidad y fecha de cierre son estimados.</p>
              </div>
              <TableEnCurso rows={rowsPendientes} {...props} />
            </TabPanel>
            <TabPanel value="3">
              <div className="pcpl-tab-header">
                <h1>Inversiones cerradas</h1>
                <p>El capital y los intereses de las operaciones ha sido reembolsado. Los cálculos de beneficios y rentabilidad son definitivos.</p>
              </div>
              <TableCerradas rows={rowsRealizadas} {...props} />
            </TabPanel>
          </TabContext>
        </Container>



      </Container>






    </div>
  );
}

export default Dashboard;
